import {Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('FillHeightContainerController');

export default class FillHeightContainerController extends Controller<HTMLDivElement> {
    static values = {
        bottomHeight: { type: String, default: '0px' }
    }

    declare bottomHeightValue: string


    connect() {
        const positionTop = this.element.getBoundingClientRect().top;
        this.element.closest('.row').classList.remove('mb-5', 'mb-4', 'mb-3', 'mb-2', 'mb-1')
        this.element.style.height = `calc(100dvh - ${positionTop}px - (${this.bottomHeightValue}))`;
    }


    disconnect() {
    }
}
